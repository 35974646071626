import Auth from '@aws-amplify/auth';
import qs from 'qs';
import getBaseUrl from './base-farmgate-url';

let impersonationId = null;

export const setApiImpersonationId = (id) => {
  impersonationId = id;
};

const sendRequest = async (baseUrl, path, method, data = null) => {
  const reqHeaders = await getHeaders();
  let url = `${baseUrl}${path}`;

  const reqOpts = {
    headers: reqHeaders,
    method,
  };

  if (method.toLowerCase() === 'get' && data) {
    url += `?${qs.stringify(data)}`;
  } else if (data) {
    reqOpts.body = JSON.stringify(data);
  }

  const response = await fetch(url, reqOpts);

  try {
    return await response.json();
  } catch (e) {
    throw new Error(`API error ${response.status}`);
  }
};

export async function getHeaders() {
  const out = {
    'Content-Type': 'application/json',
    ...(await getAuthHeaders()),
  };

  if (impersonationId) {
    out['X-Impersonation-id'] = impersonationId;
  }

  return out;
}

export async function getAuthHeaders() {
  const session = await Auth.currentSession();
  return {
    Authorization: session.getIdToken().getJwtToken(),
  };
}

export async function get(path, data) {
  return await sendRequest(getBaseUrl(), path, 'GET', data);
}

export async function post(path, data) {
  return await sendRequest(getBaseUrl(), path, 'POST', data);
}

export async function put(path, data) {
  return await sendRequest(getBaseUrl(), path, 'PUT', data);
}

export async function doDelete(path) {
  return await sendRequest(getBaseUrl(), path, 'DELETE');
}

export async function authorizedGetUrl(path, data = {}) {
  const baseUrl = getBaseUrl();

  const res = await fetch(`${baseUrl}${path}?${qs.stringify(data)}`, {
    method: 'GET',
    headers: await getAuthHeaders(),
  });

  const blob = await res.blob();

  return URL.createObjectURL(blob);
}

export async function unAuthorizedGet(path) {
  const data = await fetch(`${getBaseUrl()}${path}`);

  return data.json();
}

export async function unAuthorizedPost(path) {
  const response = await fetch(`${getBaseUrl()}${path}`, { method: 'POST' });

  if (response.ok) {
    return response;
  }

  throw new Error(
    `Error posting to ${path} ${response.status} ${response.statusText}`
  );
}
