export const PRECISION_AGRONOMIST = 4;
export const ABM = 3;
export const RBD = 2;
export const ASL = 7;
export const ADMIN = 5;
export const NON_ACCT_ADMIN = 8;

export const MCA = 1;
export const SFA = 2;
export const SOW = 3;
export const NUTRIENT_REDUCTION = 4;

export const OVERDUE = 4;
export const UNBILLED = 5;
export const UNAPPLIED = 6;

export const IN_PROGRESS = 1;
export const COMPLETED = 2;
export const CANCELLED = 3;
export const WAITING_FOR_SIGNATURE = 4;
export const SIGNATURE_RECEIVED = 5;

export const AGRONOMY_SERVICES_PRODUCT = 1;
export const SOIL_SAMPLING_PRODUCT = 2;
export const OTHER_MISCELLANEOUS_PRODUCT = 6;
export const AGRONOMY_SERVICES_ANNUAL = 7;
export const FINANCE_CHARGES = 8;
export const SOIL_SAMPLING_DISCOUNT = 9;

export const FOUR_YEAR_ROTATION = 3;

export const FULL_SERVICE = 1;
export const AG_PLUS_SOIL_SAMPLING = 2;
export const CUSTOM_SOW = 3;
export const LPK_PLUS_SOIL_SAMPLING = 4;

export const OWNER = 1;

export const HOT = 1;
export const WARM = 2;
export const COLD = 3;
export const CLOSED_LOST = 4;
export const CLOSED_WON = 5;

export const INTRODUCTION = 11;
export const DISCOVERY = 12;
export const SOLUTION_DESIGN = 13;
export const NEGOTIATION = 14;
export const COMMITMENT = 15;
export const LEAD = 16;

export const FOLLOWUP_ACTIVITY = 1;

export const AWAITING_APPROVAL = 1;
export const APPROVED = 2;
export const FLAGGED = 3;

export const CORN = 1;

export const REMIND_ME_30_MINUTES = 3;

export const BILLING_CYCLES = {
  0: ['Jan', 'Apr', 'Jul', 'Oct'],
  1: ['Feb', 'May', 'Aug', 'Nov'],
  2: ['Mar', 'Jun', 'Sep', 'Dec'],
};

export const SAMPLE_THAT = 1;
export const CASH_NOW = 2;
export const CASH_LATER = 3;
export const SOIL_SIGN_ON_BONUS = 5;
export const SUMMER_ASSESSMENT = 6;

export const PRICING_ISSUE = 1;
export const OTHER = 8;
export const PRICING_STRATEGY_ISSUE = 10;

export const CONTRACT_REPLACEMENT = 11;

export const SPECIAL_PROGRAMS_ACRES = 7500;
export const SPECIAL_PROGRAMS_MIN_AG_RATE = 14;
export const SPECIAL_PROGRAMS_MIN_SOIL_RATE = 9;

export const UNCOMMON_FARMS = 4;
export const HEARTLAND_TRUST = 18;

export const TARGET_LEAD_FARM_JOURNAL = 13;
export const TARGET_LEAD_PREFERRED_PARTNER = 5;

export const PAPER = 1;

export const TARGET_TYPE_CONTRACT_REPLACEMENT = 10;
export const TARGET_TYPE_FAMILY = 13;
export const TARGET_TYPE_B2B = 5;

export const SPRING_SAMPLE = 16;
export const FALL_SAMPLE = 17;
